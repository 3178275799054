// shared/components/ChatBotComponentBase.jsx
import React, { useState, useEffect, useRef, useMemo } from 'react';
import chatService from '../../shared/services/chatService';

const ChatBotComponentBase = ({ title, logoSrc, welcomeMessages, predefinedQuestions }) => {
    const [messages, setMessages] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);

    const EXPIRATION_TIME = 24 * 60 * 60 * 1000;
    const userId = useMemo(() => {
        let id = localStorage.getItem('userId');
        if (!id) {
            id = `user_${Math.random().toString(36).substr(2, 9)}`;
            localStorage.setItem('userId', id);
        }
        return id;
    }, []);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem(`chatHistory_${userId}`));
        const now = new Date().getTime();

        if (storedData) {
            const { messages, timestamp } = storedData;

            // Verificar si el historial ha expirado
            if (now - timestamp > EXPIRATION_TIME) {
                localStorage.removeItem(`chatHistory_${userId}`); // Borrar si expiró
            } else {
                setMessages(messages); // Cargar historial si aún es válido
            }
        }
    }, [userId, EXPIRATION_TIME]);

    useEffect(() => {
        const timestamp = Date.now();
        localStorage.setItem(`chatHistory_${userId}`, JSON.stringify({ messages, timestamp }));
    }, [messages, userId]);

    useEffect(() => {
        if (isChatOpen && messages.length === 0) {
            let delay = 0;
            welcomeMessages.forEach((message) => {
                setTimeout(() => {
                    setMessages(prevMessages => [...prevMessages, { text: message, isUser: false }]);
                    scrollToBottom();
                }, delay);
                delay += 2000;
            });
        }
    }, [isChatOpen, messages.length, welcomeMessages]);

    useEffect(() => {
        if (isChatOpen) {
            scrollToBottom();
            inputRef.current?.focus();
        }
    }, [messages, isChatOpen]);

    const toggleChat = () => setIsChatOpen(prevIsChatOpen => !prevIsChatOpen);

    const closeChat = () => setIsChatOpen(false);

    const toggleMenu = () => setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);

    const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

    const sendMessage = async (messageToSend = userMessage) => {
        if (messageToSend.trim() === '') return;

        const newMessages = [...messages, { text: messageToSend, isUser: true }];
        setMessages(newMessages);
        setUserMessage('');
        setIsLoading(true);

        try {
            const response = await chatService.sendMessage(messageToSend);
            setMessages([...newMessages, { text: response.data.message, isUser: false }]);
        } catch (error) {
            console.error('Error al enviar mensaje:', error);
            setMessages([...newMessages, { text: 'Error al procesar su solicitud', isUser: false }]);
        }

        setIsLoading(false);
    };

    const clearChatHistory = () => {
        localStorage.removeItem(`chatHistory_${userId}`);
        setMessages([]);
        setIsMenuOpen(false);
    };

    return (
        <div className={`chat-widget ${isChatOpen ? 'open' : ''}`}>
            {!isChatOpen && (
                <button onClick={toggleChat} className="chat-toggle">
                    <i className="fas fa-comments"></i> Chat con el Ayuntamiento
                </button>
            )}
            {isChatOpen && (
                <div className="chat-container">
                    <div className="chat-header">
                        <img src={logoSrc} alt={`Logo ${title}`} className="chat-logo" />
                        <span>{title}</span>
                        <button onClick={toggleMenu} className="menu-button">
                            <i className="fas fa-ellipsis-h"></i>
                        </button>
                        <button onClick={closeChat} className="close-button">
                            <i className="fas fa-times"></i>
                        </button>
                    </div>

                    <div className="chat-messages">
                        {messages.map((msg, idx) => (
                            <div key={idx} className={`message ${msg.isUser ? 'user-message' : 'bot-message'}`}>
                                <div className="message-avatar">
                                    <i className={msg.isUser ? 'fas fa-user' : 'fas fa-robot'}></i>
                                </div>
                                <div className="message-content">
                                    <span dangerouslySetInnerHTML={{ __html: msg.text }} />
                                </div>
                            </div>
                        ))}
                        {isLoading && (
                            <div className="message bot-message loading-indicator">
                                <div className="message-avatar">
                                    <i className="fas fa-robot"></i>
                                </div>
                                <div className="message-content">
                                    <div className="dot-typing">
                                        <span></span><span></span><span></span>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>

                    <div className="predefined-questions">
                        {predefinedQuestions.map((q, idx) => (
                            <button key={idx} onClick={() => sendMessage(q.question)}>
                                {q.question}
                            </button>
                        ))}
                    </div>

                    <div className="chat-input">
                        <input
                            ref={inputRef}
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                            placeholder="Escribe un mensaje..."
                        />
                        <button onClick={() => sendMessage()} disabled={isLoading}>
                            <i className="fas fa-paper-plane"></i>
                        </button>
                    </div>

                    {isMenuOpen && (
                        <div className="menu-modal">
                            <div className="menu-modal-header">
                                <h3>Opciones de Chat</h3>
                                <button onClick={() => setIsMenuOpen(false)} className="close-menu-button">
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                            <button className="remove-local-storage-menu-button" onClick={clearChatHistory}>
                                <i className="fas fa-trash-alt"></i> Borrar historial de chat
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatBotComponentBase;
