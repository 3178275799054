
import React from 'react';
import ChatBotComponentBase from '../../../shared/components/ChatBotComponentBase';

const welcomeMessages = [
    "👋 ¡Hola! Soy tu asistente digital del Ayuntamiento de Piles. Puedo hablar contigo en Español. Estoy aquí para ayudarte con cualquier pregunta que tengas.",
    "Trabajo mejor con preguntas específicas, como '¿Dónde puedo pagar mis impuestos municipales de Piles?'. 😊🎯",
    "¿Cómo puedo ayudarte hoy? 😊"
];

const predefinedQuestions = [
    { question: '¿Cuál es el horario de atención?', answer: 'El horario de atención al público del Ayuntamiento de Piles es de lunes a viernes de 8:00 a 14:30 horas.' },
    {
        question: "¿Dónde puedo pagar las tasas municipales?",
        answer: "Las tasas municipales se pueden pagar en la oficina del ayuntamiento o a través de la Oficina Virtual Tributaria: <a href=\"https://piles.es/tasas\" target=\"_blank\">Pago de tasas</a>."
    }
];

const ChatBotComponentPiles = () => (
    <ChatBotComponentBase
        title="Ayuntamiento de Piles"
        logoSrc="build/assets/piles-logo.png"
        welcomeMessages={welcomeMessages}
        predefinedQuestions={predefinedQuestions}
    />
);

export default ChatBotComponentPiles;

