import axios from 'axios';

// Definir la URL base según el entorno (desarrollo o producción)
const baseUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

// Servicio de Chatbot
const chatBotService = {
    sendMessage: (message) => {
        return axios.post(baseUrl, { message });
    }
};

export default chatBotService;
